import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BasicFooter from "./Footer"
import Header from "./header"
import "./layout.css"
import Grid from "@material-ui/core/Grid"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Grid container direction="column" justify="space-between" style={{ minHeight: "100vh" }}>
        <Grid item>
          <div
            style={{
              margin: `0 auto`,
              paddingTop: 0,
            }}
          >
            <main>{children}</main>
          </div>
        </Grid>
        <Grid item>
          <BasicFooter />
        </Grid>

      </Grid>
    )}
  />
)

export default Layout
