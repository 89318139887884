import Grid from "@material-ui/core/Grid/Grid"
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import LogoHousecallProStacked from "../images/LogoHousecallProStacked"
import Typography from '@material-ui/core/Typography'
import { getHousecallGrowthSiteBaseUrl } from "../utils/environmentUrls"

const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.blueGrayMedium,
    paddingTop: 60,
    paddingBottom: 60
  },
  bottomLogo: {
    width: 100,
    height: 100,
    fill: theme.palette.blueGrayLight
  },
  fontColor: {
    color: '#90A4AE'
  }
})

function BasicFooter({classes}) {
  return (
    <div className={classes.footer}>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography align="center" color="secondary" className={classes.fontColor}>Nominations powered by</Typography> <br/>
        </Grid>
        <Grid item xs={12} align="center">
          <a href={`${getHousecallGrowthSiteBaseUrl()}?mc=10x&msc=leaderboard`}>
            <LogoHousecallProStacked className={classes.bottomLogo} />
          </a>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(BasicFooter)
