import React from 'react'

function LogoHousecallProStacked(props) {
    const { className, style } = props

    return (
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 446.5 344"
             className={className}
             style={style}>
            <polygon points="11.372,309.232 35.8,309.232 35.8,284.283 44.347,284.283 44.347,340.989 35.8,340.989
	35.8,317.266 11.372,317.266 11.372,340.989 2.824,340.989 2.824,284.283 11.372,284.283 " />
            <g>
                <path d="M51.668,322.883c0-5.159,1.847-9.549,5.54-13.168c3.692-3.619,8.191-5.429,13.498-5.429
		c5.331,0,9.855,1.822,13.572,5.465c3.668,3.645,5.502,8.119,5.502,13.426c0,5.355-1.846,9.843-5.539,13.462
		c-3.717,3.595-8.278,5.392-13.681,5.392c-5.356,0-9.843-1.833-13.463-5.501C53.478,332.909,51.668,328.361,51.668,322.883
		 M60.105,323.03c0,3.57,0.954,6.396,2.861,8.473c1.957,2.104,4.536,3.155,7.74,3.155c3.228,0,5.808-1.04,7.74-3.118
		c1.931-2.079,2.897-4.854,2.897-8.327c0-3.472-0.966-6.248-2.897-8.326c-1.957-2.104-4.536-3.154-7.74-3.154
		c-3.154,0-5.71,1.051-7.666,3.154C61.084,316.99,60.105,319.704,60.105,323.03" />
                <path d="M105.354,305.276v20.505c0,5.918,2.335,8.877,7.006,8.877s7.006-2.959,7.006-8.877v-20.505h8.253v20.688
		c0,2.86-0.354,5.33-1.063,7.409c-0.685,1.858-1.871,3.533-3.558,5.025c-2.789,2.421-6.334,3.631-10.638,3.631
		c-4.279,0-7.813-1.21-10.601-3.631c-1.712-1.492-2.922-3.167-3.632-5.025c-0.684-1.663-1.026-4.133-1.026-7.409v-20.688H105.354z"
                />
                <path d="M158.123,311.365l-6.823,3.632c-1.075-2.201-2.408-3.301-3.998-3.301c-0.758,0-1.406,0.252-1.944,0.753
		s-0.807,1.143-0.807,1.924c0,1.369,1.59,2.728,4.768,4.072c4.378,1.883,7.325,3.619,8.841,5.209
		c1.516,1.589,2.274,3.729,2.274,6.419c0,3.448-1.271,6.333-3.815,8.656c-2.47,2.201-5.453,3.302-8.95,3.302
		c-5.991,0-10.234-2.922-12.728-8.767l7.042-3.266c0.978,1.712,1.724,2.8,2.238,3.266c1.002,0.929,2.201,1.394,3.594,1.394
		c2.788,0,4.182-1.271,4.182-3.814c0-1.468-1.076-2.837-3.228-4.108c-0.831-0.416-1.663-0.82-2.494-1.211
		c-0.832-0.391-1.675-0.795-2.531-1.21c-2.396-1.174-4.084-2.349-5.062-3.522c-1.247-1.49-1.871-3.411-1.871-5.759
		c0-3.104,1.064-5.673,3.191-7.703c2.177-2.028,4.818-3.044,7.924-3.044C152.498,304.286,155.898,306.646,158.123,311.365" />
                <path d="M202.032,325.085h-25.603c0.22,2.934,1.173,5.269,2.861,7.005c1.687,1.712,3.852,2.568,6.493,2.568
		c2.053,0,3.753-0.489,5.098-1.467c1.321-0.979,2.824-2.788,4.512-5.43l6.969,3.889c-1.076,1.834-2.213,3.407-3.411,4.716
		c-1.198,1.308-2.482,2.384-3.851,3.228c-1.37,0.845-2.85,1.459-4.439,1.851c-1.589,0.391-3.313,0.587-5.172,0.587
		c-5.331,0-9.61-1.712-12.838-5.135c-3.228-3.448-4.842-8.021-4.842-13.72c0-5.648,1.565-10.222,4.695-13.719
		c3.155-3.448,7.336-5.172,12.545-5.172c5.258,0,9.415,1.675,12.471,5.025c3.033,3.325,4.549,7.936,4.549,13.828L202.032,325.085z
		 M193.559,318.335c-1.149-4.402-3.925-6.603-8.327-6.603c-1.002,0-1.944,0.154-2.824,0.461c-0.881,0.306-1.682,0.746-2.403,1.319
		c-0.721,0.575-1.339,1.265-1.852,2.071c-0.514,0.807-0.905,1.724-1.174,2.751H193.559z" />
                <path d="M273.193,305.276h8.29v35.728h-8.29v-3.741c-3.399,3.179-7.056,4.768-10.968,4.768
		c-4.938,0-9.022-1.785-12.252-5.354c-3.203-3.645-4.805-8.192-4.805-13.646c0-5.354,1.602-9.818,4.805-13.389
		c3.204-3.57,7.214-5.355,12.031-5.355c4.158,0,7.887,1.712,11.188,5.135V305.276z M253.605,323.03c0,3.423,0.917,6.211,2.751,8.363
		c1.884,2.177,4.255,3.265,7.116,3.265c3.057,0,5.527-1.052,7.409-3.155c1.883-2.176,2.826-4.939,2.826-8.29
		c0-3.35-0.943-6.112-2.826-8.29c-1.882-2.127-4.328-3.19-7.336-3.19c-2.836,0-5.209,1.076-7.116,3.228
		C254.547,317.137,253.605,319.826,253.605,323.03" />
                <rect x="290.892" y="284.295" width="8.253" height="56.709" />
                <rect x="308.552" y="284.295" width="8.253" height="56.709" />
                <path d="M350.578,318.005v22.999h-8.547v-56.636h9.685c4.743,0,8.327,0.33,10.747,0.99
		c2.445,0.661,4.597,1.908,6.457,3.742c3.252,3.179,4.878,7.189,4.878,12.031c0,5.184-1.737,9.293-5.209,12.325
		c-3.473,3.031-8.156,4.548-14.05,4.548H350.578z M350.578,310.082h3.191c7.85,0,11.775-3.021,11.775-9.061
		c0-5.845-4.049-8.767-12.143-8.767h-2.824V310.082z" />
                <path d="M381.023,305.276h8.253v3.191c1.518-1.59,2.861-2.678,4.035-3.265c1.198-0.611,2.616-0.917,4.255-0.917
		c2.178,0,4.45,0.709,6.822,2.127l-3.777,7.557c-1.565-1.125-3.094-1.688-4.586-1.688c-4.499,0-6.749,3.399-6.749,10.198v18.524
		h-8.253V305.276z" />
                <path d="M406.773,322.883c0-5.159,1.846-9.549,5.54-13.168c3.691-3.619,8.19-5.429,13.498-5.429
		c5.331,0,9.854,1.822,13.571,5.465c3.668,3.645,5.503,8.119,5.503,13.426c0,5.355-1.847,9.843-5.539,13.462
		c-3.718,3.595-8.278,5.392-13.683,5.392c-5.354,0-9.843-1.833-13.462-5.501C408.583,332.909,406.773,328.361,406.773,322.883
		 M415.21,323.03c0,3.57,0.953,6.396,2.861,8.473c1.956,2.104,4.536,3.155,7.74,3.155c3.227,0,5.807-1.04,7.739-3.118
		c1.932-2.079,2.897-4.854,2.897-8.327c0-3.472-0.966-6.248-2.897-8.326c-1.956-2.104-4.535-3.154-7.739-3.154
		c-3.155,0-5.71,1.051-7.666,3.154S415.21,319.704,415.21,323.03" />
                <path
                    d="M229.026,311.756c0.359,0.002,0.709,0.044,1.064,0.064C229.748,311.778,229.387,311.767,229.026,311.756" />
                <path d="M228.626,311.732c0.087,0,0.167,0.013,0.253,0.014h0.003c0.05,0,0.097,0.009,0.145,0.01
		c0.36,0.012,0.722,0.022,1.064,0.063c3.074,0.174,6.001,0.966,8.769,2.458v-7.461c-3.13-1.687-6.455-2.53-9.977-2.53
		c-5.478,0-10.1,1.834-13.866,5.502c-3.765,3.668-5.648,8.168-5.648,13.498c0,5.331,1.858,9.794,5.575,13.39
		c3.717,3.569,8.339,5.354,13.866,5.354c3.351,0,6.7-0.868,10.05-2.604v-7.407c-2.92,1.514-5.841,2.327-8.763,2.521
		c-0.533,0.071-1.082,0.118-1.654,0.118c-3.105,0-5.662-1.076-7.667-3.228c-1.98-2.153-2.971-4.904-2.971-8.254
		c0-3.277,1.027-6.003,3.081-8.18C222.94,312.82,225.521,311.732,228.626,311.732" />
                <path d="M228.883,311.747h-0.004c0.051,0.001,0.098,0.007,0.147,0.009
		C228.979,311.755,228.932,311.747,228.883,311.747" />
                <polygon points="158.445,45.677 158.445,201.695 234.568,242.621 234.568,220.45 234.568,196.545 234.568,51.975
		234.568,28.069 234.568,0.852 	" />
                <polygon points="288.645,220.04 256.565,220.04 256.565,201.896 270.551,201.896 271.074,45.212 256.565,45.212
		256.565,27.071 289.265,27.071 	" />
            </g>
        </svg>

    )
}

export default LogoHousecallProStacked
