export const getNominationsClientBaseUrl = () => {
  if (process.env.GATSBY_ENV === 'staging') {
    return 'https://share.housecallpro-staging.com'
  }
  return process.env.NOMINATIONS_CLIENT_URL
}

export const getOnlineBookingBaseUrl = () => {
  if (process.env.GATSBY_ENV === 'staging') {
    return 'https://book.housecallpro-staging.com'
  }
  return process.env.ONLINE_BOOKING_BASE_URL
}

export const getHousecallGrowthSiteBaseUrl = () => {
  if (process.env.GATSBY_ENV === 'staging') {
    return 'https://www.housecallpro-staging.com'
  }
  return process.env.HOUSECALL_GROWTH_SITE_BASE_URL
}
